import { authorizationStore } from '@/store/authorization';
import axios from 'axios';
import appInsights from '@/AppInsights';
import safeStringify from 'fast-safe-stringify';

const endpoints = {
	next: 'http://192.168.1.245:3030',
	prod: 'https://app-updates.shootpro.cloud',
};

const useDevServer =
	false &&
	(window.location.host.includes('localhost') ||
		window.location.host.includes('beta') ||
		window.location.host.includes('next'));

const appUpdateAxios = axios.create({
	baseURL: useDevServer ? endpoints.next : endpoints.prod,
});

appUpdateAxios.interceptors.request.use(function (config) {
	const authStore = authorizationStore();
	if (authStore.token) {
		config.headers.Authorization = `Bearer ${authStore.token}`;
	}
	config.headers['x-from-service'] = 'shooter-side-ui';
	console.log('⚡\tRequesting update process');
	return config;
});

appUpdateAxios.interceptors.response.use(
	(response) => {
		// Any status code from range of 2xx
		// Do something with response data
		appInsights.trackEvent({
			name: `req.appUpdate.success`,
			properties: {
				_message: `${error}`,
				...JSON.parse(safeStringify(response)),
			},
		});
		return response;
	},
	(error) => {
		// Any status codes outside range of 2xx
		// Do something with response error
		appInsights.trackEvent({
			name: `req.appUpdate.fail`,
			properties: {
				_message: `${error}`,
				...JSON.parse(safeStringify(error)),
			},
		});
		return Promise.reject(error);
	}
);

export default appUpdateAxios;
